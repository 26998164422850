@tailwind base;

@tailwind components;

@tailwind utilities;

.w25 {
	width: 19%;
}

.w75 {
	width: 81%;
}

.m-auto {
	margin: auto;
}

.truncate {
	display: block;
	display: -webkit-box;
	width: 61px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
